<template>
  <div class="cart_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">购物车</div>
      <div style="position:relative;font-size:16px;" :class="haveCate ? 'show-this' : 'hidden-this'" @click="isAdmin = !isAdmin">
        <van-icon name="arrow-left" size="24" color="#fff" />
        <span style="position:absolute;left:-10px;top:5px;width:2em;height:24px;align-items:center;"
          v-if="isAdmin">管理</span>
        <span style="position:absolute;left:-10px;top:5px;width:2em;height:24px;align-items:center;" v-else>完成</span>
      </div>
    </div>
    <div class="bar"></div>
    <div v-if="!this.myCart || this.myCart == {} || this.myCart.items.length == 0">
      <van-empty v-if="false" class="custom-image" :image="require('../../assets/images/空的.png')"
        description="购物车还没有商品，去商城逛逛吧">
        <div>
          <div class="btn" @click="toHome">
            前往商城
          </div>
        </div>
      </van-empty>
      <div class="empty-status">
        <div class="empty-img">
          <van-image
  width="7.25rem"
  height="6.5625rem"
  :src="require('../../assets/images/空的.png')"
/>
        </div>
        <div class="u-like">
          猜你喜欢
        </div>
        <div style="color:#CDCDCD;margin-top:.9375rem;font-size:.875rem;letter-spacing: 0.35px;">
          购物车还没有商品，去商城逛逛吧
        </div>
        <div class="my-btn" @click="toHome">
          <div>
            前往商城
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-checkbox-group v-model="result" @change="handleChange">
        <div v-for="(item,index) in myCart.items" :key="index" class="cart_good_box">
          <div style="display:flex;align-items:center;padding-left:10px;background-color:#fff;">
            <van-checkbox :name="index"></van-checkbox>
          </div>
          <div style="width:100%;">
            <goods-cart-confirm @handleChangeCart="handleChangeCart" :orderindex="index" :order="item"></goods-cart-confirm>
          </div>
        </div>
      </van-checkbox-group>
      <div class="buy_bar" v-if="isAdmin">
        <div>
          <van-checkbox v-model="checkAll" @click="handleChangeCheckAll">全选</van-checkbox>
        </div>
        <div class="total-box">
          <div >总计：￥{{allPrice}}</div>
          <div >已优惠:￥0</div>
        </div>
        <div @click="buyGoods">
          <div class="pay_btn">结算({{allCount}})</div>
        </div>
      </div>
      <div class="buy_bar" v-else>
        <div>
          <van-checkbox v-model="checkAll" @click="handleChangeCheckAll" style="font-size:1rem;color:#999999;letter-spacing: 0.4px;">全选</van-checkbox>
        </div>
        <div>
          &nbsp;
        </div>
        <div @click="delCart">
          <div class="pay_btn">删除</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GoodsCartConfirm from "@/components/goods/GoodsCartConfirm.vue";
  import MyBtn from "@/components/common/MyBtn.vue";
  export default {
    components: {
      GoodsCartConfirm,
      MyBtn
    },
    data() {
      return {
        value: 1,
        price: 99,
        myCart: {
          items: []
        },
        allCount: 0,
        allPrice: 0,
        result: [],
        isAdmin: true,
        checkAll: false
      };
    },
    watch: {
      value(val) {
        this.price = val * this.price;
        console.log(val);
      },
      checkAll(val) {
        
      }
    },
    created() {
      this.getCart()
    },
    computed:{
      haveCate(){
        let bool =  !this.myCart || this.myCart == {} || this.myCart.items.length == 0
        return !bool
      }
    },
    methods: {
      handleChangeCheckAll(){
        let val = this.checkAll 
        if (val) {
          this.result = Array.from(this.myCart.items, (x, index) => {
            return index
          })
        } else {
          this.result = []
        }
      },
      toHome() {
        // let backlen = window.history.length - 1;
        // window.history.go(-backlen + 1);
        this.$closePage()
      },
      delCart() {
        let myCart = this.myCart
        myCart.items = myCart.items.filter((x, index) => {
          return this.result.indexOf(index) == -1
        })
        this.result = []
        this.myCart = myCart
        this.editCart()
      },
      handleChange() {
        if(this.result.length == this.myCart.items.length){
          this.checkAll = true
        } else {
          this.checkAll = false
        }
        this.getAllCountAndAllPrice()
      },
      handleChangeCart(order, index) {
        this.myCart.items[index] = order;

        window.sessionStorage.setItem("myCart", JSON.stringify(this.myCart));
        this.$emit('changeCart', this.myCart)
        this.editCart()
      },
      getAllCountAndAllPrice() {
        let allPrice = 0
        let allCount = 0
        for (let i of this.result) {
          allPrice += Number(this.myCart.items[i].price)
          allCount += Number(this.myCart.items[i].count)
        }
        this.allPrice = allPrice
        this.allCount = allCount
      },
      buyGoods() {
        if (this.result.length == 0) {
          return this.$toast.fail('未选择商品')
        }
        let order = []
        for (let i of this.result) {
          order.push(this.myCart.items[i])
        }
        this.$bus.$data.order = order;
        this.$router.push("/confirmorder?fromcart");
      },
      async editCart() {
        let items = this.myCart.items.map(x => {
          x.goods_id = x.id
          x.image = x.images
          return x
        })
        let {
          data: res
        } = await this.$http.post('/api/editGoodsCart', {
          items
        })
        if (res.statusCode !== 0) {
          this.$toast.fail(res.msg)
        }
        this.getAllCountAndAllPrice();
      },
      async getCart() {
        let {
          data: res
        } = await this.$http.post('/api/getGoodsCart')
        if (res.statusCode !== 0) {
          this.$toast.fail(res.msg)
        }
        this.myCart = {
          items: res.data.map(x => {
            x.id = x.goods_id
            x.images = x.image
            x.price = Number(x.original_price) * x.count
            return x
          })
        }
        window.sessionStorage.setItem("myCart", JSON.stringify(this.myCart));
      }
    }
  };
</script>

<style lang="less" scoped>
.empty-img{
  margin-top: 7.25rem;
  
}
.u-like{
    margin-top: 2rem;
    color: #333;
letter-spacing: 0.45px;
font-size: 18px;
  }
.hidden-this{
  opacity: 0;
  color: #fff;
}
.my-btn{
  margin-top: 6.25rem;
  display: flex;
  justify-content: center;
  align-items: cneter;
  >div{
    width: 12.5rem;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--fc--);
    border-radius: 2rem;
    color: #fff;
    letter-spacing: 0.4px;
font-size: 1rem;
  }
}
  .btn {
    width: 200px;
    color: #fff;
    padding: 8px 15px;
    background-color: #F6736E;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pay_btn {
    width: 6.875rem;
    height: 2rem;
    
    background-color: var(--fc--);
    color: #fff;
    border-radius: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.6px;
font-size: 1rem;
line-height: 1rem;
  }

  .cart_box {
    padding-top: 5.5rem;
    position: relative;
    height: 100vh;
    background-color: #F7F7F5;

    .cart_good_box {
      display: flex;
      margin-bottom: 1px;
    }

    .shadow {
      border-radius: 5px;
      box-shadow: 0 0 5px 5px #eee;
    }

    .bar {
      background-color: #f9f9f9;
      height: 10px;
    }

    .buy_bar {
      position: fixed;
      z-index: 444;
      bottom: 0;
      left: 0;
      height: 3.75rem;
      width: 100%;
      background-color:#f3f3f3;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 .9375rem;
      /deep/.van-checkbox__label{
        color: #999 !important;
      }
      .total-box{
        flex-grow: 1;
        
        margin: 0 .9375rem;
        height: 100%;
        
        >div{
          height: 50%;
        }
        >div:last-child{
          height: 15px;
          color: rgba(153, 153, 153, 1);
          letter-spacing: 0.25px;
          font-size: .625rem;
          font-weight: 400;
          text-align: right;
        }
        >div:first-child{
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          
color: rgba(246, 115, 110, 1);
letter-spacing: 0.4px;
font-size: 1rem;
font-weight: 400;
        }
      }
    }
  }
</style>