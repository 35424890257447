<template>
  <div class="goods_pre_box">
    
    <div class="img_pre">
      <div class="goods_img">
        <img :src="order.images" alt style="width:100%;height:100%;object-fit:cover;" />
      </div>
    </div>
    <div class="msg_pre">
      <div class="msg_title">
        <div>{{order.title}}</div>
        <div style="color:red;font-size:16px;position:relative;" v-if="false">￥{{order.original_price}}
        </div>
      </div>
      <div class="goods_des">
        <div>金色<van-icon name="arrow-down" /></div>
        <div></div>
      </div>
      <div class="card_bottom">
        <div>
          ￥{{order.original_price}}
        </div>
        <van-stepper v-model="value" theme="round" button-size="18" disable-input />
      </div>
    </div>
  </div>
</template>

<script>
import MyBtn from "@/components/common/MyBtn.vue";
export default {
  props: ["order","orderindex"],
  components: {
    MyBtn
  },

  data() {
    return {
      value: 1,
      url:''
    };
  },
  created() {
    this.value = this.order.count
    this.url = window.location.href
  },
  watch: {
    value(val) {
      let order = this.order
      order.count = val
      order.price = order.original_price * val
      this.$emit("handleChangeOrder", order, this.orderindex);
      this.$emit("handleChangeCart",order,this.orderindex)
    }
  },
  computed: {
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.goods_pre_box {
  padding: 1.4375rem .625rem;
  display: flex;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  .goods_des{
    
    display: flex;
    
    color: #999;
    >div:first-child{
      width: 3.75rem;
      height: 1.125rem;

      font-size: .75rem;
      background-color: #D9D9D9;
      color: #999999;
      padding-left: .5rem;
      padding-right: .3125rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
    }
    >div:last-child{
      background-color: #D9D9D9;
    }
  }
  .img_pre {
    flex-shrink: 0;
    width: 4.75rem;
    height: 4.75rem;
    .goods_img {
      width: 100%;
      height: 100%;
      background-color: #eee;
    }
    
  }
  .msg_pre {
    width: 100%;
    padding-left:.625rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .msg_title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 12rem;
      div{
        display: flex;
        align-items:center;
        font-size: .75rem;
        color: #333;
      }
    }
    .card_bottom {
      display: flex;
      justify-content: space-between;
      > div {
        letter-spacing: 0.45px;
font-size: 1.125rem;
        color: var(--fc--);
      }
    }
  }
}
</style>